.root {
  background: rgba(0, 0, 0, 0.5);
}

.title {
  font-family: Nunito;
  font-weight: bold;
  font-size: 1.1rem;
  color: #fff;
}

.copyright {
  padding-top: 10px;
  height: 100%;
  padding-bottom: 10px;
}

.email {
  color: white;
  padding-left: 10px;
  padding-top: 5px;
}
